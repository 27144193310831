import React, { useMemo } from "react";
import styles from "./styles.module.css";

interface TypographyProps
  extends React.HTMLAttributes<HTMLParagraphElement | HTMLHeadingElement> {
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p1" | "p2" | "p3";
  color?: "main" | "subtitle" | "description" | "accent";
}

const componentMap = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  p1: "p",
  p2: "p",
  p3: "p",
};

const Typography: React.FC<TypographyProps> = ({
  children,
  variant = "p2",
  color = "main",
  className,
  ...rest
}) => {
  const Component = useMemo(
    () => componentMap[variant],
    [variant]
  ) as keyof JSX.IntrinsicElements;

  return (
    // @ts-ignore
    <Component
      data-color={color}
      data-variant={variant}
      className={`${styles.typography} ${className || ""}`}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Typography;
