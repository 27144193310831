import React from "react";
import styles from "./styles.module.css";

import SvgInstagramIcon from "@/icons/instagram.svg";
import SvgVkIcon from "@/icons/vk.svg";
import SvgBehanceIcon from "@/icons/behance.svg";
import SvgDzenIcon from "@/icons/dzen.svg";
import SvgTelegramIcon from "@/icons/telegram.svg";
import SvgMailIcon from "@/icons/mail.svg";
import SvgLinkIcon from "@/icons/link.svg";
import SvgEyeIcon from "@/icons/eye.svg";
import SvgPdfIcon from "@/icons/pdf.svg";
import SvgDropDownIcon from "@/icons/drop-down.svg";
import SvgUpRightArrowIcon from "@/icons/up-right-arrow.svg";
import SvgUserIcon from "@/icons/user.svg";
import SgvCheckMarkIcon from "@/icons/check-mark.svg";
import SgvRightArrowIcon from "@/icons/right-arrow.svg";
import SgvLeftArrowIcon from "@/icons/left-arrow.svg";
import SgvDownArrowIcon from "@/icons/down-arrow.svg";
import SgvBurgerMenuIcon from "@/icons/burger-menu.svg";
import SgvCloseIcon from "@/icons/close.svg";
import SvgTaskCheckIcon from "@/icons/task-check.svg";
import SvgAnalyticsIcon from "@/icons/analytics.svg";
import SvgUnreadIcon from "@/icons/unread.svg";
import SvgUploadIcon from "@/icons/upload.svg";

type Props = React.HTMLProps<HTMLElement>;

export const InstagramIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgInstagramIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const VkIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgVkIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const BehanceIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgBehanceIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const DzenIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgDzenIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const TelegramIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgTelegramIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const MailIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgMailIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const LinkIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgLinkIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const EyeIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgEyeIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const PdfIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgPdfIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const DropDownIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgDropDownIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const UpRightArrowIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgUpRightArrowIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const UserIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgUserIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const CheckMarkIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SgvCheckMarkIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const RightArrowIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SgvRightArrowIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const LeftArrowIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SgvLeftArrowIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const DownArrowIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SgvDownArrowIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const BurgerMenuIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SgvBurgerMenuIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const CloseIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SgvCloseIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const TaskCheckIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgTaskCheckIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const AnalyticsIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgAnalyticsIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const UnreadIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgUnreadIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
export const UploadIcon: React.FC<Props> = ({ className, ...rest }) => (
  <SvgUploadIcon
    className={`${styles.icon} ${className ? className : ""}`}
    {...rest}
  />
);
