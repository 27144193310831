import * as React from "react";
import { Slot, Slottable } from "@radix-ui/react-slot";

import styles from "./styles.module.css";

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  variant?: "circle" | "square";
  size?: "md" | "sm";
  Icon: React.ComponentType<React.HTMLProps<HTMLElement>>;
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      variant = "square",
      size = "md",
      className,
      children,
      Icon,
      asChild,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        data-size={size}
        data-variant={variant}
        className={`${styles.button} ${className ? className : ""}`}
        ref={ref}
        {...props}
      >
        <Slottable>{children}</Slottable>
        {Icon ? <Icon className={styles.icon} /> : null}
      </Comp>
    );
  }
);
IconButton.displayName = "IconButton";

export default IconButton;
export type { IconButtonProps };
